import { SESSION_KEY } from '../constants';

const uuid = () => {
  // From https://stackoverflow.com/a/2117523/594784
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
};

export const setAttributes = (element, attrs) => {
  for (let key in attrs) {
    if (attrs.hasOwnProperty(key)) {
      element.setAttribute(key, attrs[key]);
    }
  }
  return element;
};

export const createElement = (type, attrs = {}, parent = null) => {
  let element = setAttributes(document.createElement(type), attrs);
  parent && parent.append(element);
  return element;
};

export const getGuid = () => {
  let guid = sessionStorage.getItem(SESSION_KEY);
  if (!guid) {
    guid = uuid();
    sessionStorage.setItem(SESSION_KEY, guid);
  }
  return guid;
};
