import React from 'react';
import EmbarkLogo from '../../static/embark_logo_white_L_1.svg';
import WimLogo from '../../static/wim_logo_white.svg';

export const Footer = () => {
  return (
    <footer className="footer">
      <div>
        <a href="https://wim.live/">
          <img className="footer-logo" src={WimLogo} alt="wim logo" />
        </a>
      </div>
      <div className="wim-copyright">
        WIM © 2023 Embark Studios AB.WIM, WORLDS I MADE and EMBARK trademarks
        and logos are trademarks or registered trademarks of Embark Studios AB.
      </div>

      <div className="footer-divider">&nbsp;</div>

      <div>
        <a href="https://embark.games/">
          <img
            className="footer-embark-logo"
            src={EmbarkLogo}
            alt="Embark logo"
          />
        </a>
      </div>
      <div className="footer-right-links-container">
        <a href="https://www.embark-studios.com/press" target="_blank">
          Press
        </a>
        <a href="https://www.embark-studios.com/privacy-policy" target="_blank">
          Privacy Policy
        </a>
        <a href="https://wim.live/cookie-policy" target="_blank">
          Cookie Policy
        </a>
      </div>
    </footer>
  );
};
