import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import EmbarkLogo from '../../static/embark_logo_white_L_1.svg';
import WimLogo from '../../static/wim_logo_white.svg';

const isLinkNav = (pn) => {
  console.log(pn);
  const viewsWithoutLinks = ['/install', '/login', '/login/unauthorized', '/'];
  return !viewsWithoutLinks.includes(pn);
};

const SimpleNav = () => {
  return (
    <nav className="nav-only-logo">
      <Link to="/">
        <img alt={'Embark Studios Logotype - Home Link'} src={EmbarkLogo} />
      </Link>
    </nav>
  );
};

const LinkNav = () => {
  return (
    <nav className="nav-main">
      <div className="nav-main-inner">
        <Link to="/">
          <img
            className="nav-main-logo"
            alt={'Embark Studios Logotype - Home Link'}
            src={EmbarkLogo}
          />
        </Link>

        <Link to="/">
          <img
            className="nav-link-logo"
            alt={'wim - Home Link'}
            src={WimLogo}
          />
        </Link>
      </div>
    </nav>
  );
};

export const Nav = () => {
  let location = useLocation();
  return isLinkNav(location.pathname) === true ? <LinkNav /> : <SimpleNav />;
};
