import React from 'react';
import ReactDOM from 'react-dom';

import { createElement } from './core/utils';
import './app.css';
import { AppPublic } from './views/AppPublic';

const appWrapper = document.getElementById('app');
const deeplinkPattern = /ark:\/\//;
const downloadLinkPattern = /\.exe/;

const clickHandler = async (event) => {
  let { target } = event;
  if (target.tagName.toLowerCase() === 'a') {
    const { href } = target;
    if (href.match(deeplinkPattern) || href.match(downloadLinkPattern)) {
      event.preventDefault();
      setTimeout(() => {
        let downloadIframe = document.getElementById('iframeDownloader');
        if (!downloadIframe) {
          downloadIframe = createElement(
            'iframe',
            {
              id: 'iframeDownloader',
              width: '1',
              height: '1'
            },
            document.getElementById('app')
          );
        }
        downloadIframe.src = href;
      }, 100);
    }
  }
};

const removeHandlers = () => {
  document.removeEventListener('click', clickHandler);
};

document.addEventListener('click', clickHandler);
window.addEventListener('unload', removeHandlers);

ReactDOM.render(<AppPublic />, appWrapper);
