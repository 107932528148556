import React, { useEffect, useState } from 'react';
import { getGuid } from '../core/utils';
import protocolDetection from 'custom-protocol-detection';
import { Nav } from './Nav';
import WimLogo from '../../static/wim_logo_green.svg';

export const WimLauncher = ({ url, label, params = '' }) => {
  let iframeLauncher = React.createRef();
  let [hasExe, setHasExe] = useState(true);

  let wimTrackingURL = `wim://${url}?web_id=${getGuid()}`;
  if (params.length > 0) {
    wimTrackingURL += '&' + params;
  }

  useEffect(() => {
    setTimeout(() => {
      protocolDetection(
        wimTrackingURL,
        () => {
          // Failed detecting exe
          setHasExe(false);
        },
        () => {
          // Success detecting exe
          setHasExe(true);
        },
        () => {
          // Unsupported exe
          setHasExe(false);
        }
      );
    }, 1000);
  }, []);

  return (
    <main className={'container applet-launcher gradient-link-page'}>
      <div className="home">
        <button className="button button-big button-dark button-big-link">
          <a href={wimTrackingURL} className={'btn'}>
            launching
            <img src={WimLogo} alt="wim" className="link-logo" />
            ...
          </a>
        </button>

        {!hasExe && (
          <div>
            <p>&nbsp;</p>
            <p className="lead">
              You need to be part of the wim early community access to access
              this content.
            </p>
            <p className="lead">
              <button className="button button-small button-light button-no-bg button-small-link">
                <a href="https://ark.embark.dev">Download installer</a>
              </button>
            </p>
          </div>
        )}
      </div>
      <iframe
        ref={iframeLauncher}
        width="1"
        height="1"
        style={{ visibility: 'hidden' }}
      />
    </main>
  );
};
