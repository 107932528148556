import React from 'react';
import { Nav } from '../components/Nav';
import { useLocation } from 'react-router-dom';

export const Unauthorized = () => {
  let location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const externalId = queryParams.get('external_id');
  return (
    <>
      <main className="gradient-landing-page">
        <Nav />
        <div className="home">
          <h1>Whoops!</h1>
          <h2 className="unauthorized-subtitle">
            Sorry, you are not allowed to view this page.
          </h2>

          {externalId ? (
            <h2 className="unauthorized-subtitle">
              To request access, please send the code{' '}
              <span class="text-highlight">{externalId}</span> to an embark
              admin.
            </h2>
          ) : (
            ''
          )}
        </div>
      </main>
    </>
  );
};
