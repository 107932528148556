import React from 'react';
import { BrowserRouter as Router, withRouter } from 'react-router-dom';
import { PublicRoutes } from '../components/Routes';
import { Footer } from '../components/Footer';

export const AppPublic = () => {
  const Routes = withRouter(PublicRoutes);
  return (
    <Router>
      <>
        <div className="page">
          <Routes />

          <Footer />
        </div>
      </>
    </Router>
  );
};
