import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { WimLauncher } from '../components/WimLauncher';

export const NaiveLauncher = () => {
  let params = useParams();
  let module_matcher = /(ark\/)?(.*)/;
  let matches = module_matcher.exec(params[0]);
  let url = '';
  if (matches != null) {
    url = matches[0];
  }
  let { search } = window.location;
  return (
    <WimLauncher
      url={`module/${url}`}
      label={url}
      params={search ? search.replace('?', '') : ''}
    />
  );
};

export const JoinLauncher = () => {
  // pathname is typically "user", but have also been "session", "server", etc
  let { pathname, search } = window.location;
  return (
    <WimLauncher
      // skip the first `/` in the path name that is always there
      url={`${pathname.substring(1)}`}
      label="join"
      params={search ? search.replace('?', '') : ''}
    />
  );
};

export const UpdateLauncher = () => {
  let location = useLocation();
  let url = location.pathname.replace(/^\/+/, '');
  let search = location.search;
  return (
    <WimLauncher
      url={`${url}`}
      label={url}
      params={search ? search.replace('?', '') : ''}
    />
  );
};
