import React from 'react';
import { useData } from '../hooks/data';
import { Nav } from '../components/Nav';

const docLinks = {
  'ark-api': (
    <a href="https://ark.embark.dev/api/ark_api/" key="ark-api">
      <h3>💎</h3>
      <h4>wim API</h4>
      <p>API docs for our wim-api crate</p>
    </a>
  ),
  'modding-guide': (
    <a
      href="https://urban-bassoon-g3gkm41.pages.github.io/"
      key="modding-guide">
      <h3>🗺️</h3>
      <h4>wim Modding Guide</h4>
      <p>How to mod wim</p>
    </a>
  ),
  'sandbox-api': (
    <a href="https://ark.embark.dev/sandbox-api/sandbox_api/" key="sandbox-api">
      <h3>💎</h3>
      <h4>Sandbox API</h4>
      <p>API docs for our sandbox-api crate</p>
    </a>
  ),
  'ark-internals-guide': (
    <a href="https://ark.embark.dev/internals/" key="ark-internals-guide">
      <h3>🔬</h3>
      <h4>wim Internals Guide</h4>
      <p>How wim works internally and how to contribute to it</p>
    </a>
  ),
  'ark-internals-crates': (
    <a
      href="https://ark.embark.dev/internals-crates/ark_file_system/index.html"
      key="ark-internals-crates">
      <h3>📦</h3>
      <h4>wim Internals Crates</h4>
      <p>wim internals crates listing</p>
    </a>
  ),
  'sandbox-internals-guide': (
    <a
      href="https://ark.embark.dev/sandbox-internals/"
      key="sandbox-internals-guide">
      <h3>🏖️</h3>
      <h4>Sandbox Internals Guide</h4>
      <p>How the Sandbox works internally</p>
    </a>
  ),
  'ark-dev-guide': (
    <a href="https://ark.embark.dev/guide/" key="ark-dev-guide">
      <h3>🗺️</h3>
      <h4>wim Developer Guide</h4>
      <p>How to develop modules in Ark</p>
    </a>
  )
};

const developmentDocs = ['ark-api', 'modding-guide', 'sandbox-api'];

const internalsDocs = [
  'ark-internals-guide',
  'ark-internals-crates',
  `sandbox-internals-guide`,
  `ark-dev-guide`
];

export const Home = () => {
  const loginStatus = useData('/login_status');

  const checkAccess = (id) =>
    loginStatus &&
    loginStatus.doc_access &&
    loginStatus.doc_access.includes(id);

  const devDocAccess = developmentDocs.filter(checkAccess);
  const internalsDocsAccess = internalsDocs.filter(checkAccess);

  return (
    <>
      <main className="gradient-landing-page">
        <Nav />

        <div className="home">
          <h1>Welcome to wim</h1>

          <button className="button button-big button-dark button-install-landing">
            <a href="/install">Download installer</a>
          </button>

          {devDocAccess.includes('modding-guide') && (
            <button className="button button-small button-light">
              <a href="https://urban-bassoon-g3gkm41.pages.github.io/">
                Read the modding guide
              </a>
            </button>
          )}
        </div>
      </main>
      {devDocAccess.length > 0 && (
        <section>
          <h2 className="links-header">Development</h2>
          <div className="main-links flex-row">
            {devDocAccess.map((id) => docLinks[id])}
          </div>
        </section>
      )}
      {internalsDocsAccess.length > 0 && (
        <section>
          <h2 className="links-header">Internals</h2>

          <div className="main-links flex-row">
            {internalsDocsAccess.map((id) => docLinks[id])}
          </div>
        </section>
      )}
    </>
  );
};
