import { useState, useEffect } from 'react';

export const useData = (url) => {
  const [state, setState] = useState();

  useEffect(() => {
    let mounted = true;
    const dataFetch = async () => {
      const data = await (await fetch(url)).json();

      if (mounted) {
        setState(data);
      }
    };

    dataFetch().catch(console.error);
    return () => {
      mounted = false;
    };
  }, [url]);

  return state;
};
