import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { Home } from '../views/Home';
import { Install } from '../views/Install';
import { Login } from '../views/Login';
import { Unauthorized } from '../views/Unauthorized';
import {
  JoinLauncher,
  NaiveLauncher,
  UpdateLauncher
} from '../views/NaiveLauncher';

export const PrivateRoutes = () => {
  return (
    <Switch>
      <Route exact={true} path="/install">
        <Install />
      </Route>
      <Route exact={true} path="/">
        <Home />
      </Route>
      <Route exact={true} path="/unauthorized">
        <Unauthorized />
      </Route>
    </Switch>
  );
};

export const PublicRoutes = () => {
  return (
    <Switch>
      <Route exact={true} path="/join/*">
        <JoinLauncher />
      </Route>
      <Route exact={true} path="/login">
        <Login />
      </Route>
      <Route exact={true} path="/login/unauthorized">
        <Unauthorized />
      </Route>
      <Route exact={true} path="/ark/module/*">
        <NaiveLauncher />
      </Route>
      <Route exact={true} path="/module/*">
        <NaiveLauncher />
      </Route>
      <Route exact={true} path="/update/*">
        <UpdateLauncher />
      </Route>
    </Switch>
  );
};
