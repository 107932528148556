import React from 'react';
import { Nav } from '../components/Nav';

const discordLoginURL = '/login/discord';

const googleLoginURL = '/login/google';

export const Login = () => {
  return (
    <>
      <main className="gradient-landing-page">
        <Nav />
        <div className="home">
          <h1>Welcome to the wim portal!</h1>

          <h2 className="login-subtitle">
            Log in using your preferred platform.
          </h2>

          <div className="auth-links">
            <a href={googleLoginURL}>
              <svg
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                role="img">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M24.354 27.99v-7.812H44.01c.294 1.323.525 2.562.525 4.305C44.535 36.474 36.492 45 24.375 45c-11.592 0-21-9.408-21-21s9.408-21 21-21c5.67 0 10.416 2.079 14.049 5.481l-5.964 5.796c-1.512-1.428-4.158-3.108-8.085-3.108-6.951 0-12.621 5.775-12.621 12.852s5.67 12.852 12.621 12.852c8.043 0 11.004-5.565 11.55-8.862H24.354v-.02z"
                  fill="currentColor"></path>
              </svg>
              <h3>Google</h3>
            </a>

            <a href={discordLoginURL}>
              <svg
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                role="img">
                <g clipPath="url(#discord_svg__a)">
                  <path
                    d="M40.634 8.562a39.581 39.581 0 0 0-9.77-3.03.148.148 0 0 0-.157.074c-.422.75-.89 1.73-1.217 2.499a36.542 36.542 0 0 0-10.973 0 25.261 25.261 0 0 0-1.236-2.499.154.154 0 0 0-.157-.074 39.472 39.472 0 0 0-9.77 3.03.14.14 0 0 0-.064.055C1.067 17.914-.638 26.982.198 35.938a.165.165 0 0 0 .063.112c4.105 3.016 8.082 4.846 11.986 6.06a.155.155 0 0 0 .168-.056 28.448 28.448 0 0 0 2.452-3.988.152.152 0 0 0-.083-.212 26.217 26.217 0 0 1-3.745-1.784.154.154 0 0 1-.015-.256c.252-.188.504-.384.744-.583a.149.149 0 0 1 .155-.02c7.856 3.586 16.36 3.586 24.123 0a.148.148 0 0 1 .157.019c.24.198.492.396.745.584.087.065.082.2-.013.256a24.594 24.594 0 0 1-3.746 1.782.153.153 0 0 0-.081.214 31.938 31.938 0 0 0 2.45 3.986.152.152 0 0 0 .168.057c3.922-1.213 7.9-3.043 12.005-6.059a.154.154 0 0 0 .062-.11c1.001-10.354-1.676-19.348-7.097-27.32a.122.122 0 0 0-.062-.058zM16.04 30.485c-2.365 0-4.314-2.171-4.314-4.838 0-2.666 1.911-4.838 4.314-4.838 2.422 0 4.352 2.19 4.314 4.838 0 2.667-1.911 4.838-4.314 4.838zm15.95 0c-2.365 0-4.314-2.171-4.314-4.838 0-2.666 1.91-4.838 4.314-4.838 2.421 0 4.351 2.19 4.313 4.838 0 2.667-1.892 4.838-4.313 4.838z"
                    fill="currentColor"></path>
                </g>
                <defs>
                  <clipPath id="discord_svg__a">
                    <path
                      fill="currentColor"
                      transform="translate(0 5.25)"
                      d="M0 0h48v37.183H0z"></path>
                  </clipPath>
                </defs>
              </svg>
              <h3>Discord</h3>
            </a>
          </div>
        </div>
      </main>
    </>
  );
};
